// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

export const environment = {
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// let webUrl = 'http://localhost:8080/iotify-web';
// let adminUrl = 'http://localhost:8090/iotify-admin/';
// // const webSocketUrl = 'ws://localhost:8080/iotify-web/dashboard';
// webUrl = 'https://remon.in/iotify-web';
// adminUrl = 'https://remon.in/iotify-admin/';
// const webSocketUrl = 'wss://remon.in/iotify-web/dashboard';



let urlsBasedhost={
  localhost:
  'http://localhost:8080/iotify-web@http://localhost:8090/iotify-admin/@ws://localhost:8080/iotify-web/dashboard',
  ems:
  'https://remon.in/iotify-web@https://remon.in/iotify-admin/@wss://remon.in/iotify-web/dashboard',
  iot:
  'https://remon.in/iotify-web@https://remon.in/iotify-admin/@wss://remon.in/iotify-web/dashboard',
  remon:
  'https://remon.in/iotify-web@https://remon.in/iotify-admin/@wss://remon.in/iotify-web/dashboard',
omc:
'https://omc.remon.in/iotify-web@https://omc.remon.in/iotify-admin/@wss://omc.remon.in/iotify-web/dashboard',
};
// omc:'http://52.172.92.18:8080/iotify-web@http://52.172.92.18:8090/iotify-admin/@ws://52.172.92.18:8080/iotify-web/dashboard',
let manualSethost='omc';
let host=manualSethost||window.location.hostname.split('.')[0];
let urlsArray:string[]=urlsBasedhost[host].split('@');
console.log(urlsArray);
let webUrl =  urlsArray[0];
let adminUrl =  urlsArray[1];
const webSocketUrl =  urlsArray[2];

// const webSocketUrl = 'ws://192.168.0.115:8080/iotify-web/dashboard';
// webUrl ='http://192.168.0.115:8080/iotify-web';
// adminUrl =  'http://192.168.0.115:8090/iotify-admin/';


const adminSocketUrl = adminUrl;

export const dashboardDataSocket = {
  staticVariable: undefined,
  liveVariable: undefined,
  liveStructure: undefined,
};

export const serverUrl = {
  webUrl: webUrl,
  adminUrl: adminUrl,
  webSocketUrl: webSocketUrl,
  adminSocketUrl: adminSocketUrl,
};
// export var commonJson={
// loginDtoJson:undefined
// };
