import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { WebSocketConnectionService } from './web-socket-connection.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Subject, Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
// import { log } from 'console';
@Injectable({
  providedIn: 'root',
})
export class CommonService implements OnDestroy {
  isLoading: boolean;
  // apphostMain={
  //   hostName:'',
  // }
  // chartWidth: number = this.platform.width();
  isEmslogin=0;
  loaderdata = {
    isloading: false,
    message: '',
    timer: 0,
  };
  AjaxCall;
  headerJson: string = this.parsingjsoninfo();
  customtimers = [];
  userConfig={};
  emsMinMaxProps={
    min:0,
    max:0,
    avg:0,
    dew:0,
    mean:0
  };
  //site name binding
  siteNameSelcted='';
  //site name binding
  // header: string = localStorage.getItem('headerJson');
  isEmtyObj = (obj) => {
    return !Boolean(Object.keys(obj).length);
  };
  isnotEmty = (obj) => Boolean(Object.keys(obj).length);
  isEmtyArr = (arr) => {
    return !Boolean(arr.length);
  };
  subject = new Subject<any>();
  componentsSubject = new Subject<number>();
  applicationuijson;
 //dummychart data
 dummyChartO = {   
  chart: {
     type: "spline"
  },  title: { text: '' },
  xAxis:{
     categories:[]
  },
  yAxis: {          
     title:{
        text:"Data"
     } 
  },
  series: [ ]
};
 
 //dummychart data 
  // subscriptions = new Subscription();
  constructor(
    private router: Router,
    public activeRoute: ActivatedRoute,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public webSocketConnectionServices: WebSocketConnectionService,
    public screenOrientation: ScreenOrientation,
    private platform: Platform,
   // private loc: Location,
  ) {
   
  // this.AjaxCall= this.injector.get<AjaxService>(AjaxService);
    // let mypro = this.screenOrientation.onChange().subscribe((o) => {
    //   if (this.screenOrientation.type == 'landscape-primary') {
    //     let setwidthtime = setTimeout(() => {
    //       this.chartWidth = this.platform.width();
    //       this.componentsSubject.next({
    //         id: 'chartwidth',
    //         chartwidth: this.chartWidth,
    //       });
    //       console.log(
    //         this.screenOrientation.type,
    //         'orientaion type',
    //         this.platform.width()
    //       );
    //       clearTimeout(setwidthtime);
    //     }, 1200);
    //   }
    // });
    // this.subscriptions.add(mypro);
    // console.log(this.subscriptions, 'subscr');

  };
//  appMainVariableCreation(){
//   this.apphostMain.hostName=window.location.hostname.split('.')[0];
//  }

  preventivemaintenanceScale(imgpath,text,hourcumulative){
    return`   <button class="preventivebutton" style="margin: 0 auto; display: block; border-radius: 45px !important;
    background: white; width: 80px; height: 80px; border: 7px solid #a58d8d; 
    transition: 0.1s;">
     <img src="assets/img/grid/preventive4.png" style="width: 52px; height: 46px; margin: 3px 0px -10px -8px;">
     <span style="display: block; text-align: center; margin-bottom: 0px; font-size: small;">Reset</span>
   </button>
   <label style="display: block; text-align: center; margin-bottom: 0px; font-size: 11px; line-height: 100%;" class="ng-binding">Preventive
     Maintenance<br>${hourcumulative ||0} Hrs Due</label>`
  }
  preventivesetClick(id){

  }
  convertDateString = (date: string) => {
    if (date) return new Date(date.replace(' ', 'T')).getTime();
  };

  dataSharing(): Observable<any> {
    return this.subject.asObservable();
  };
  highchartsLoadEvent=(event)=>{
    console.log(event,'events');
    if(!event.target['reflow'])return null;
    let reflowchart= setTimeout(() => {
      event.target.reflow();
     clearTimeout(reflowchart);
   }, 2000);
  }

  ngOnDestroy(): void {
    // console.log('service destroy');
    
    // this.subscriptions.unsubscribe();
  };
  userFeatureConfig(s='',obj?){
    let json=s || sessionStorage.getItem('headerJson');
    let tried=true;
    let featureConfig:string[];
    try {
      featureConfig    = JSON.parse(json);
      tried=false;
    } catch (error) {
      console.log('cant parsing');
    }
  if(tried){
    let c=json.replace(/\\/g, '').replace(/\"/g, '').replace(/\'/g, '');
    featureConfig= c.slice(1, -1).split(',')
  };
   
    // let {headerJ}=obj
    let userFeature = {
      Dashboard: 'T',
      Manage_Fleets: '',
      Alert_Configuration: '',
      Alerts:'',
      Reports: '',
      Alarms: '',
      company_management:'',
      Vehicles:'',
      Simcard:'',
      Device:'',
      Ticket_Management:'',
      __Remote:'',
      Map:''
    };
featureConfig.forEach(ui=>{if(ui in userFeature)userFeature[ui]='T'});
this.userConfig =userFeature;
    return userFeature;
  }

  parsingjsoninfo(s?) {
    let json: string = s || sessionStorage.getItem('headerJson');
    let dashboardInfo = {
      header: '',
      reports: '',
      manage: '',
      companysettings: '',
      otherinfo: '',
    };
    // console.log('header called ', s);
    if (this.headerJson) return this.headerJson;
    if (!json) return '';
    let c = json.replace(/\\/g, '').replace(/\"/g, '').replace(/\'/g, '');
    let reportdata = c.slice(1, -1).split(',');
    let headerjs = {
      Dashboard: 'DASHBOARD',
      Manage_Fleets: 'MANAGE',
      Alert_Configuration: 'ALERTS',
      Reports: 'REPORTS',
      Alarms: 'ALARMS',
      company_management:'company_management',
      result: '',
    };

    let myarr = reportdata.forEach((x) => {
      if (x.endsWith('Report')) {
        dashboardInfo.reports += `${x},`;
      } else if (x == 'company_management') {
        dashboardInfo.companysettings = '1';
      } else dashboardInfo.otherinfo += `${x},`;
    });
    Object.entries(headerjs).forEach((x) => {
      if (c.includes(x[0])) {
        headerjs.result += x[1] + ',';
      }
    });
    dashboardInfo.header = headerjs.result.slice(0, -1);
    //console.log(dashboardInfo, 'dashboardinfoo');

    return (this.headerJson = headerjs.result.slice(0, -1));
  }
  // @HostListener('window:resize', ['$event'])
  // onWindowResize() {
  //   this.chartWidth = window.innerWidth;
  //   console.log(this.chartWidth, 'chatsize');
  // }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  };
  logout() {
    //this.commonService.presentLoader();
    // console.log('log Out!!!');
    localStorage.clear();
    sessionStorage.clear();
    this.headerJson='';
    // this.loc.replaceState('/login');

    this.router.navigate(['/login']);
    // this.router.navigateByUrl('login', { replaceUrl: true }).finally(() => {
    //   // this.commonService.dismissLoader();
      
    // });
  };
  isAdminUser(){
    let isadmin= localStorage.getItem('loginData')?.includes('CompanyAdmin');
    let userHasaccess=false
    if(this.userConfig['__remote'] ||isadmin) userHasaccess=true;
     return userHasaccess;
  }

   presentLoader(classname?: string, customduration: number = 9000,isneedreload=0) {
    // let lang = sessionStorage.getItem("language");
    // let message = lang == 'ar' ? "أرجو الإنتظار !" : "Please Wait!";
    let messagee = 'Loading...';
    if (this.loaderdata.isloading) return null;
    if (this.router.url == '/login') messagee = 'Please Wait!';
    this.loaderdata.message = messagee;
    let setmytime = setTimeout(() => {
      this.dismissLoader('selfclosing',1);
      // if(isneedreload)
    }, customduration);
    this.customtimers.push(setmytime);
    this.loaderdata.isloading = true;

    // console.log('loader called', this.isLoading);
  }

  dismissLoader(classname?: string,from?) {
    // this.loaderdata.isloading = false;
    if(from){
      this.loaderdata.isloading=false;
    }else{
  let loader=    setTimeout(()=>{
        this.loaderdata.isloading=false;
        clearTimeout(loader);
      },1000)
    }
  
    // console.log(this.customtimers, 'before');
    clearTimeout(this.customtimers.pop());
    // console.log(this.customtimers, 'after');
  }

  signOut(purpose='') {
  let maintainTrackversion=localStorage.getItem('TrackVersion');
  let cred = localStorage.getItem('rememberMe')
    localStorage.clear();
    sessionStorage.clear(); 
    localStorage.setItem('TrackVersion',maintainTrackversion);
    let parsedCred = JSON.parse(cred);
    if(parsedCred['loginCheckbox']){
      localStorage.setItem('rememberMe',cred);
    }
    this.siteNameSelcted='';
    this.webSocketConnectionServices.closeConnection();
    let url=`/login${purpose}`;
    this.router.navigateByUrl(url, { replaceUrl: true});
  
    
  }

  isPresent(arrayList: string[], checkList: string[], isMathched = false) {
    if (isMathched) return checkList.every((el) => arrayList.includes(el));
    return checkList.some((el) => arrayList.includes(el));
  }
  screenport() {
    this.screenOrientation.unlock();
    return this.screenOrientation
      .lock(this.screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY)
      .then(
        (success) => console.log('success', this.platform.width()),
        (failure) => console.log(failure)
      );
  }
  screenland(id = 1) {
    this.screenOrientation.unlock();
    if (this.screenOrientation.type == 'landscape-primary' && id) return null;
    return this.screenOrientation
      .lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY)
      .then(
        (success) => {
          console.log('success', this.platform.width());
        },
        (failure) => console.log(failure)
      );
  }
  // appOnbackground(){
  //   this.platfm.resume.unsubscribe();
  //   this.screenport()
  // }
  segment(trends) {
    if (trends) this.screenport();
    //this.appPause=false
  }
  isDesktop(): boolean {
    return ['desktop', 'tablet'].some((platform) =>
      this.platform.platforms().includes(platform)
    );
  }
  arrayLooping(array: any[], callback?) {
    array.forEach((x, i) => {
      callback(x, i);
    });
  }
  getImportantVars(input) {
    let vars = {
      loginData: JSON.parse(localStorage.getItem('loginData')),
      loginSiteData: JSON.parse(localStorage.getItem('loginJson')),
    };
    return vars[input];
  }
  localstragevars = (name: string) =>
    JSON.parse(localStorage.getItem(name)) || null;


    applicationUiJson(usern=''){
      let scopeloggingInfos=JSON.parse(localStorage.getItem('loginData'));
      let hosts={
        ems:'roshan',
        iot:'common',
        remon:'common'
      }
      if(!scopeloggingInfos)return;
      let comname =usern|| scopeloggingInfos['companyId'];
      let newAppjson={
        ems:1,
        header:0,
        companySettings:'',
        loginpage:'ems.remon.in.png',
        gridview:'moderngrid',
        fontcolor:'white',
        component_content:'#150464,#24034b,#37022e'
      }
      let Allcompany={
        eaems:{
        ...newAppjson
        },
        ems:{
        ...newAppjson
        },
        common:{
          ems:0,
          header:1,
          companySettings:'',
          gridview:'grid',
          loginpage:'iot.remon.in.jpg',
          fontcolor:'black',
          component_content:'#fff,#fff,#fff'
        }
      };
      // 
     
      let stylethemes={
        newone:['#150464','#24034b','#37022e','#2716768f','#eae9f1','#382a78','#382a78','#382a78','white','#4e4087'],
        oilfeild:['black','black','black','transparent','white','lightgray','orange','13px','white','gray'],
        availablevars:['--iot-backgroundPrimary','--iot-backgroundSecondary','--iot-backgroundTertiary','--iot-backgroundContentGrid',
      '--iot-backgroundContentFont','--iot-backgroundHover','--iot-backgroundSelected','--iot-fontContentSize',
      '--iot-fontColor','--iot-borderThemeP','--iot-borderThemeS','--iot-borderThemeT',],
        oldone:['white','white','white','white','black','inherit','#ffffff3d'],
      };
      let backgroundUrl=`url('../../assets/img/grid/ems.remon.in.png')center center / cover no-repeat fixed`;
      // let root=document.querySelector(':root');
      //! oil field
    // stylethemes.availablevars.forEach((props,indexofcolor)=>{
    //  let stylesheet=  document.documentElement.style;
    //  stylesheet.setProperty(props,stylethemes['oilfeild'][indexofcolor]);
    //  console.log(props,stylesheet.getPropertyValue(props));
    // });
    let preventj=(Allcompany[comname])?comname:'common';
    //  sessionStorage.setItem('appuiJson',JSON.stringify(Allcompany[comname]));
    this.applicationuijson=Allcompany[preventj];
    //!some default setting in style
     let appStyle= document.documentElement.style;
     let comcontent=this.applicationuijson['component_content'];
    appStyle.setProperty('--iot-loginbackground',backgroundUrl);
    appStyle.setProperty('--iot-fontColor',this.applicationuijson['fontcolor']);
    // appStyle.setProperty('--iot-backgroundPrimary',comcontent[0]);
    // appStyle.setProperty('--iot-backgroundSecondary',comcontent[1]);
    // appStyle.setProperty('--iot-backgroundTertiary',comcontent[2]);
    //!some default setting in style
   

    this.isEmslogin=this.applicationuijson['ems']||0;

  // set margin to router outlet as 0
  let headerM='36px'
  if(this.isEmslogin){
    headerM='0'
  }
  appStyle.setProperty('--header-margin',headerM);
  console.log('0 margin',appStyle.getPropertyValue('--iot-fontColor'));
  
// document.getElementById('main').style.marginTop='0';

  // set margin to router outlet as 0
    
    // console.log(val,'roooooooooot');
     
      console.log(preventj,this.applicationuijson,this.isEmslogin);
      return Allcompany[preventj];
    };
    // themesSettingFunctiton(pagename,id){
    //   let pagesClass={
    //     1:{site_modal:''}
    //   }
    // }
pagesThemeSettings(pname){
  let pageNames={
    settings:[]
  }
};
getSiteName(key:string){
  if(!key)return '';
    let livedata=JSON.parse(sessionStorage.getItem('liveDatas')||'{}');
    let sitename= [];
 key.split(',').forEach(element=>{sitename.push(livedata[element]?.['plateNo']) })
    return sitename.join('**');
   
}
};

